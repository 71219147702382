import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProductsService,
  showProductService,
  reviewProductService,
  returnProductService,
  getReturnOrderDetailsService,
  getEstimatedDeliveryService,
  getProductReviewsService,
  getAllServices,
  getSearchProductSuggestionService,
  clearSearchHistorySuggestionService,
  getSearchHistoryProductSuggestionService,
} from "../../services/product.service";
// import { getRewardsService, showRewardService } from "../../services/reward.service"
import {
  getMerchantServiceService,
  getMerchantServicesService,
} from "../../services/merchant.service";

export const getProducts = createAsyncThunk(
  "/getProducts",
  async (params, { rejectWithValue }) => {
    switch (params.type) {
      case "REWARDS":
        // delete params.type;
        return await getProductsService(params, rejectWithValue);
      case "SERVICES":
        delete params.type;
        return await getMerchantServicesService(params, rejectWithValue);
      case "products":
        // delete params.type;
        return await getProductsService(params, rejectWithValue);
      default:
        // delete params.type;
        return await getProductsService(params, rejectWithValue);
    }
  }
);

export const getServices = createAsyncThunk(
  "/getServices",
  async (payload, { rejectWithValue }) => {
    return await getAllServices(payload, rejectWithValue);
  }
);

export const showProduct = createAsyncThunk("/showProduct", async (params) => {
  switch (params.type) {
    case "REWARDS":
      delete params.type;
      return await showProductService(params.id);
    case "SERVICES":
      delete params.type;
      return await getMerchantServiceService(params.id);
    default:
      delete params.type;
      return await showProductService(params.id);
  }
});

export const getProductReviewsAction = createAsyncThunk(
  "/getProductReviews",
  async (payload, { rejectWithValue }) => {
    return await getProductReviewsService(payload, rejectWithValue);
  }
);

export const addProductReview = createAsyncThunk(
  "/addProductReview",
  async (payload, { rejectWithValue }) => {
    return await reviewProductService(payload, rejectWithValue);
  }
);

export const returnProduct = createAsyncThunk(
  "/returnProduct",
  async (payload, { rejectWithValue }) => {
    return await returnProductService(payload, rejectWithValue);
  }
);

export const getReturnOrderDetails = createAsyncThunk(
  "/getReturnOrderDetails",
  async (payload, { rejectWithValue }) => {
    console.log("getReturnOrderDetailsService Triggered");
    return await getReturnOrderDetailsService(payload, rejectWithValue);
  }
);

export const getEstimatedDelivery = createAsyncThunk(
  "/getEstimatedDelivery",
  async (payload, { rejectWithValue }) => {
    return await getEstimatedDeliveryService(payload, rejectWithValue);
  }
);

export const getSearchProductsSuggestion = createAsyncThunk(
  "/getSearchProductsSuggestion",
  async (params, { rejectWithValue }) => {
    return await getSearchProductSuggestionService(params, rejectWithValue);
    // switch (params.type) {
    //   case "REWARDS":
    //     // delete params.type;
    //     return await getProductsService(params, rejectWithValue);
    //   case "SERVICES":
    //     delete params.type;
    //     return await getMerchantServicesService(params, rejectWithValue);
    //   case "products":
    //     // delete params.type;
    //     return await getSearchProductSuggestionService(params, rejectWithValue);
    //   default:
    //     // delete params.type;
    //     return await getSearchProductSuggestionService(params, rejectWithValue);
    // }
  }
);

export const getSearchHistoryProductsSuggestion = createAsyncThunk(
  "/getSearchHistoryProductsSuggestion",
  async (params, { rejectWithValue }) => {
    return await getSearchHistoryProductSuggestionService(params, rejectWithValue);
  }
);

export const clearSearchHistorySuggestion = createAsyncThunk(
  "/clearSearchHistorySuggestion",
  async (params, { rejectWithValue }) => {
    return await clearSearchHistorySuggestionService(params, rejectWithValue);
  }
);
